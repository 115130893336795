





























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import Swiper from 'swiper';
import moment from 'moment';
import { ticketModule, reservationModule } from '@/store';
import DataFormat from '@/shared/utils/dataFormat';
import { EventBus } from '@/config/config';
import { auth } from '@/shared/auth';
import { find, findIndex } from 'lodash';

@Component
export default class ToReserve extends mixins(DataFormat) {
  @Prop({
    required: true
  })
  public type!: any;
  @Prop()
  public timeSlot!: any;
  @Prop()
  public selectLang!: any;
  @Prop()
  public cartData!: any;
  @Prop()
  public ableReservationDate!: any;
  @Prop()
  public reserveModal!: any;

  // @Prop()
  // public realReservationDate!: any;
  // @Prop()
  // public scheduleDate!: string;

  public reserveDate: any = moment().add('1', 'days').format('YYYY-MM-DD');
  public reservePerson: number = 2;
  public reserveTime: any = null;
  public timeCheck: number = 0;
  public reserveClose: boolean = false;
  public optionChecked: any = [];
  public setTimeLoading: boolean = false;

  public params: any = {
    id: this.type.id,
    date: this.ableReservationDate.reservation_date !== '' ? this.ableReservationDate.reservation_date : this.reserveDate,
    person: this.reservePerson
  }

  public swiper: any = null;
  public timeList: any = [];

  public beforeDestroy() {
    this.setTimeLoading = false;
  }

  public eventClickTimeblock(time) {
    const eventName = 'click_timeblock';
    const eventParams: any = {
      time: null,
      type: null,
      id: null,
      name: null
    }

    if (this.$route.name === 'place') {
      eventParams.time = time,
      eventParams.type = 'place',
      eventParams.id = this.type.id.toString(),
      eventParams.name = this.type.name
    } else if (this.$route.name === 'product') {
      eventParams.time = time,
      eventParams.type = 'product',
      eventParams.id = this.type.id.toString(),
      eventParams.name = this.type.title
    }
  }

  public toData(index) {
    if (this.timeSlot.times[index].is_active) {
      this.timeActiveAction(index);
      this.$emit('reservation-date', this.params.date);
    }
    // if (auth.getAccessUserId() === '') {
    //   this.$modal.show('login');
    // } else {
    //   if (this.schedule && this.schedule.schedules[index].active) {
    //     this.timeActiveAction(index);
    //   } else if (!this.schedule && this.timeSlot.schedules[index].active) {
    //     this.timeActiveAction(index);
    //   }
    // }
  }

  public timeActiveAction(index) {
    EventBus.$emit('updateReserve', this.params);
    EventBus.$emit('updateReserveDate', this.params.date);
    EventBus.$emit('updateReserveTime', this.reserveTime);
    this.timeCheck = index;
    EventBus.$emit('updateTimeCheck', this.timeCheck);
    EventBus.$emit('timeBlockIndex', this.timeCheck);
    EventBus.$emit('optionChecked', this.optionChecked);
    this.reserveClose = false;
    this.$modal.show('toReserve');
    if (this.reserveTime) {
      this.eventClickTimeblock(this.reserveTime);
    }
  }

  @Watch('reserveTime')
  public fetchReserveTime() {
    this.optionChecked = [];
  }

  public timeDisable(index) {
    if (!this.timeSlot.times[index].is_active || this.params.date === null) {
      return true;
    } else {
      return false;
    }
    // if (this.schedule) {
    //   if (!this.schedule.schedules[index].active || this.params.date === null) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // } else {
    //   if (!this.timeSlot.times[index].is_active || this.params.date === null) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // }
  }

  // @Watch('realReservationDate')
  // public realReservation() {
  //   this.activeSwiper(this.timeCheck);
  //   if (this.realReservationDate) {
  //     this.params.date = this.realReservationDate;
  //     const params: any = {
  //       reservation_date: this.params.date,
  //       person: this.params.person
  //     }
  //     if (this.$route.name === 'product') {
  //       params.product_id = this.params.id;
  //       reservationModule.fetchProductReservationTime(this.params).then((res: any) => {
  //         if (res.status) {
  //           // 최소,최대 인원수 확인
  //           if (res.data.min_size > this.params.person) this.params.person = res.data.min_size;
  //           else if (res.data.max_size < this.params.person) this.params.person = res.data.max_size;

  //           const isActive = find(res.data.schedules, (row) => row.active === true);
  //           if (!isActive && !res.data.reservation_date) {
  //             // reservationModule.fetchProductSchedule(this.params);
  //             this.reserveDate = null;
  //             this.params.date = null;
  //           }
  //         }
  //       });
  //     } else {
  //       // params.place_id = this.params.id;
  //       reservationModule.fetchPlaceTime(this.params).then((res: any) => {
  //         if (res.status) {
  //           // 최소,최대 인원수 확인
  //           if (res.data.min_size > this.params.person) this.params.person = res.data.min_size;
  //           else if (res.data.max_size < this.params.person) this.params.person = res.data.max_size;

  //           const isActive = find(res.data.schedules, (row) => row.active === true);
  //           if (!isActive && !res.data.reservation_date) {
  //             // reservationModule.fetchPlaceSchedule(this.params);
  //             this.reserveDate = null;
  //             this.params.date = null;
  //           }
  //         }
  //       });
  //     }
  //   }
  // }

  // @Watch('reserveClose')
  // public fetchData() {
  //   if (this.reserveClose && this.params.date !== null) {
  //     this.activeSwiper(this.timeCheck);
  //     if (this.$route.name === 'product') {
  //       reservationModule.fetchProductTime(this.params);
  //     } else {
  //       reservationModule.fetchPlaceTime(this.params);
  //     }
  //   }
  // }
  @Watch('ableReservationDate')
  public ableReservationDateUpdate() {
    const re = this.ableReservationDate.capacity.filter((row: any) => {
      return row.size === 2;
    });
    if(re.length) {
      const activeIndex = re[0].times.findIndex((row: any) => {
        return row.is_active;
      })
      this.timeCheck = activeIndex;
    }
    this.params.date = this.ableReservationDate.reservation_date !== '' ? this.ableReservationDate.reservation_date : this.reserveDate;
    this.$emit('reservation-date', this.params.date);
    // if (document.querySelector('.timeline-list-slide') && this.timeCheck) {
    //   setTimeout(() => {
    //     this.activeSwiper(this.timeCheck);
    //   }, 500);
    // }
  }
  // @Watch('timeCheck')
  // public timeCheckUpdate() {
  //   if (document.querySelector('.timeline-list-slide') && this.timeCheck) {
  //     setTimeout(() => {
  //       this.activeSwiper(this.timeCheck);
  //     }, 500);
  //   }
  // }
  @Watch('params.date')
  public dateUpdate() {
    if (document.querySelector('.timeline-list-slide') && this.timeCheck && this.params.date) {
      setTimeout(() => {
        this.activeSwiper(this.timeCheck);
      }, 500);
    }
  }
  public mounted() {
    this.setTimeLoading = true;
    this.params.id = this.type.id;
    if (this.ableReservationDate) {
      // this.reserveDate = this.scheduleDate;
      this.params.date = this.ableReservationDate.reservation_date !== '' ? this.ableReservationDate.reservation_date : this.reserveDate;
    }
    if (this.$route.params.reserveDate) {
      this.params.date = this.$route.params.reserveDate;
      this.reserveTime = this.$route.params.reserveTime;
      this.timeCheck = Number(this.$route.params.timeCheck);
    }

    if (this.$route.params.person) {
      this.params.person = Number(this.$route.params.person);
      this.reservePerson = Number(this.$route.params.person);
    }

    EventBus.$on('updateReserve', (data) => {
      this.params = data;
    })
    EventBus.$on('updateReserveTime', (data) => {
      this.reserveTime = data;
    })
    EventBus.$on('updateTimeCheck', (data) => {
      this.timeCheck = data;
    })
    EventBus.$on('updateReserveModal', (data) => {
      this.reserveClose = data;
    })
    EventBus.$on('optionChecked', (data) => {
      this.optionChecked = data;
    })

    const params: any = {
      reservation_date: this.params.date,
      person: this.params.person
    }
    if (this.$route.name === 'product' && params.reservation_date !== undefined) {
      // this.params.id = this.$route.params.productId;
      params.product_id = this.$route.params.productId;
      reservationModule.fetchProductReservationTime(params).then((res: any) => {
        if (res.status) {
          // if (this.params.person < res.data.min_size) this.params.person = res.data.min_size;
          const person2Times = res.data.capacity.filter((row: any) => {
            return row.size === 2;
          });
          const isActive = person2Times[0] ?
            find(person2Times[0].times, (row) => row.is_active === true) :
            null;
          // if (!isActive) {
          //   // reservationModule.fetchProductSchedule(this.params);
          //   this.reserveDate = null;
          //   this.params.date = null;
          // }
        }
      });
    } else {
      this.params.id = this.$route.params.placeId;
      reservationModule.fetchPlaceTime(this.params).then((res: any) => {
        if (res.status) {
          if (this.params.person < res.data.min_size) this.params.person = res.data.min_size;
          const isActive = find(res.data.schedules, (row) => row.active === true);
          // if (!isActive && !res.data.reservation_date) {
          //   // reservationModule.fetchPlaceSchedule(this.params);
          //   this.reserveDate = null;
          //   this.params.date = null;
          // }
        }
      });
    }
    setTimeout(() => {
      this.activeSwiper(this.timeCheck);
    }, 500);
  }
  get reservationTimeData() {
    return reservationModule.productReservationTime;
  }
  // get timeSlot() {
  //   if (this.ableReservationDate && this.ableReservationDate.capacity) {
  //     if (this.$route.name === 'product') {
  //       const size2Time: any = this.ableReservationDate.capacity.filter((row: any) => {
  //         return row.size === 2;
  //       })
  //       if (size2Time) {
  //         return size2Time[0];
  //       }
  //       return null;
  //     } else {
  //       return reservationModule.placeTime;
  //     }
  //   }
  // }

  public eventClick() {
    const eventName = 'click';
    const eventParams: any = {
      button_type: 'reserve',
      type: null,
      id: null,
      name: null
    }

    if (this.$route.name === 'place') {
      eventParams.type = 'place',
      eventParams.id = this.type.id.toString(),
      eventParams.name = this.type.name
    } else if (this.$route.name === 'product') {
      eventParams.type = 'product',
      eventParams.id = this.type.id.toString(),
      eventParams.name = this.type.title
    }
  }

  public reserveGo() {
    this.eventClick();
    this.$emit('reservation-date', this.params.date);
    EventBus.$emit('updateReserve', this.params);
    if (this.params.date) EventBus.$emit('updateReserveDate', this.params.date);
    else EventBus.$emit('updateReserveDate', null);
    EventBus.$emit('optionChecked', this.optionChecked);
    if (this.reserveTime) {
      EventBus.$emit('updateReserveTime', this.reserveTime);
      EventBus.$emit('updateTimeCheck', this.timeCheck);
    }
    if (this.reservationTimeData && this.reservationTimeData.party_size_detail) {
      if (this.cartData[`person_${this.reservationTimeData.party_size_detail[0].key}`]) {
        EventBus.$emit('updatePersonDetail', this.cartData);
      }
    }
    this.reserveClose = false;
    this.$modal.show('toReserve');
  }

  public activeSwiper(first) {
    if (!this.setTimeLoading) {
      return;
    }
    if (this.swiper) {
      this.swiper.destroy();
      this.swiper = null;
    }
    this.swiper = new Swiper('.to-reserve-container .timeline-list-slide', {
      slidesPerView: 'auto',
      initialSlide: first,
      freeMode: true,
      observer: true,
      observeParents: true,
    });
  }

  @Watch('$route.params')
  public routeChange() {
    this.reserveDate = moment().add('1', 'days').format('YYYY-MM-DD');
    this.reservePerson = 2;
    this.reserveTime = null;
    this.params.date = this.ableReservationDate.reservation_date !== '' ? this.ableReservationDate.reservation_date : this.reserveDate;
    this.params.person = 2;
    if (this.$route.name === 'product') {
      this.params.id = this.$route.params.productId
    } else {
      this.params.id = this.$route.params.placeId
    }
  }

  @Watch('reserveModal')
  public reserveModalUpdate() {
    if (document.querySelector('.timeline-list-slide') && this.reserveTime && this.params.date) {
      setTimeout(() => {
        this.activeSwiper(this.timeCheck);
      }, 500);
    } else {
      this.activeSwiper(0);
    }
  }

  get schedule() {
    if (this.$route.name === 'product') {
      return reservationModule.productSchedule;
    } else if (this.$route.name === 'place') {
      return reservationModule.placeSchedule;
    } else {
      if (this.type.product === null) {
        return reservationModule.placeSchedule;
      }
      return reservationModule.productSchedule;
    }
  }
}
